.notice__container {
    width: 100%;
    background: #0d2735;
    height: 200px;
    display: flex;
    position: relative;
    margin-top: 50px;
  }
  
  .figure {
    display: none;
  }
  
  .notice__options {
    display: none;
  }
  
  .notice__options--text {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }
  
  .notice__title--container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .notice__title {
    color: #FAFAFA;
    width: 76%;
    text-align: center;
    font-size: 20px;
  }


/* Estilos para móvil (default) */
.notice__title--container {
  display: block;
}

/* Estilos para escritorio */
@media (min-width: 768px) {
  .notice__title--container {
    display: none;
  }
  
  /* Opcional: Asegurar que el banner se vea bien en desktop */
  .figure img {
    width: 100%;
    height: auto;
  }
}


  .form-container {
    background: rgba(154, 208, 255, 0.37);
    padding-bottom: 50px;
    align-content: center;
  }
  
  .form__requirement {
    font-size: 11px;
    padding: 10px 0;
    text-align: center;
    color: #454854;
    font-weight: 500;
  }
  
  .wirk__form {
    width: 91.5%;
    margin: 0 auto;
  }
  
  .options__information--container {
    background-color: #FDFDFF;
    border-radius: 10px;
    padding: 10px 20px 19px;
    margin-bottom: 20px;
  }
  
  .options__information--container:nth-child(2) {
    padding-bottom: 40px;
  }
  
  .options__information--title {
    display: flex;
    justify-content: center;
    color: #0d2735;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #C8D0E3;
    margin-top: 7px;
    padding-bottom: 20px;
  }
  
  .options__information--fields {
    display: block;
    gap: 10px;
    margin-top: 20px;
  }
  
  .options__information--labels {
    flex: 1;
    padding-right: 0;
    border-right: none;
  }
  
  .options__information--labels:last-child {
    border-right: none;
  }
  
  .options__information--label {
    margin-bottom: 5px;
  }
  
  .form-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .form-row {
    padding-left: 20px;
    margin-top: 30px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #666C7C;
  }
  
  .form-check-label {
    margin-left: 10px;
    font-size: 10px;
    font-weight: 600;
    color: #1C1B1F;
  }
  
  .form-input,
  .form-select {
    width: -webkit-fill-available;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    color: #1C1B1F;
  }
  
  .form-input:focus,
  .form-select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .form-check-input:checked {
    background-color: #0d2735;
    border-color: transparent;
  }
  
  .form-check-input:focus {
    box-shadow: none;
  }
  
  .data__treatment {
    background-color: #C7E0F5;
    border-radius: 10px;
  }
  
  .data__treatment--text {
    color: #1C1B1F;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    padding: 24px 28px 0;
  }
  
  a.data__treatment--text {
    color: #12186B;
    padding: 0px;
  }
  
  .data__treatment--check {
    color: #1C1B1F;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    padding: 10px 28px 24px;
  }
  
  .button__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-submit-button {
    background-color: #0d2735;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 20px;
    font-weight: 600;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  .form-logout-button {
    background-color: #0d2735;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-top: 20px;
    font-weight: 600;
    transition: all 0.3s ease;
    border-radius: 4px;
  }
  
  .form-submit-button:hover {
    background-color: #12186B;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .figure {
      display: inline-block;
    }
  
    .notice__options {
      position: absolute;
      top: 25px;
      left: 68px;
      display: flex;
      align-items: center;
    }
  
    .notice__title--container {
      width: 64%;
    }
  
    .notice__title {
      font-size: 24px;
    }
  
    .form__requirement {
      text-align: center;
    }
  
    .options__information--title {
      display: block;
      font-size: 12px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .options__information--fields {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .options__information--labels {
      padding-right: 15px;
      border-right: 1px solid #C8D0E3;
    }
  
    .form-label {
      font-size: 10px;
      font-weight: 500;
    }
  
    .form-input,
    .form-select {
      font-size: 11px;
    }
  
    .button__container {
      display: block;
    }
  
    .form-submit-button {
      border-radius: 4px;
    }
  }
  
  /* Estilos para el mensaje de agradecimiento */
  .thank_you {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 70vh;
    display: block;
    background-color: #f9fafa;
    justify-content: center;
    align-items: center;
  }
  
  .thank_you__image{
    margin: 150px;
  }
  
  .thank_you__text{
    margin-right: 150px;
  }
  
  @media (min-width: 768px) {
    .thank_you {
      display: flex;
    }
  }
  
  @media (max-width: 768px) {
    .thank_you__image {
      display: flex;
      justify-content: center;
    }
  }
  
  .thank_you__link {
    flex: auto;
    appearance: none;
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    background: #0d2735;
    display: inline-block;
    font-size: 12.5px;
    font-weight: 600;
    line-height: normal;
    margin: 5px;
    min-height: 2.75em;
    outline: none;
    padding: 10px;
    text-align: left;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    color: #FAFAFA;
    border-radius: 4px;
  }
  
  .thank_you__link:hover {
    color: #FAFAFA;
    background: #12186B;
  }
  