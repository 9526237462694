.header {
  background-color: #FFFFFF;
  box-shadow: 0 4px 27px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #e8e8e8;
  padding: 13px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
}

.header__container {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.header--logo {
  padding-left: 25px;
}

/* Ocultar logo en escritorio */
@media (min-width: 416px) {
  .header--logo {
    display: none;
  }
  
  /* Ajustes para el menú en desktop */
  .navbar {
    margin: 0 auto; /* Centrar el menú */
  }
  
  .navbar ul {
    display: flex;
    gap: 20px;
  }
  
  .mobile-nav-toggle {
    display: none; /* Ocultar el icono de menú hamburguesa */
  }
}

.header__buttons {
  display: flex;
  gap: 10px;
}

.header__button {
  display: inline-block;
  padding: 11px 15px;
  background-color: #0d2735;
  color: white;
  border-radius: 8px;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.18;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.1px;
  text-align: center;
}

@media (max-width: 415px) {
  .header__button {
    padding: 11px 21px;
  }

  .header__buttons {
    display: grid;
    gap: 10px;
  }
  
  /* Asegurar que el menú hamburguesa sea visible en móvil */
  .mobile-nav-toggle {
    display: block;
  }
  
  /* Ocultar items de navegación en móvil (si usas menú hamburguesa) */
  .navbar ul {
    display: none;
  }
}

.header__button:hover {
  background-color: #12186B;
  transform: translateY(-1px);
  color: #FFFFFF;
}

.header--image {
  height: 47px;
}

/* Estilos adicionales para el menú de navegación */
.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar a {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #12186B;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #0b212d;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #0b212d;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #1c3745;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #68A4C4;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #0b212d;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(17, 38, 48, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #1e4356;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #68A4C4;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #68A4C4;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}